<template>
  <dashboard-filters-v2>
    <template #secondary>
      <dashboard-filters-v2-item
        v-for="(link, index) in links"
        :key="index"
      >
        <router-link
          :to="link.to"
          exact-active-class="has-text-primary"
          class="SettingsNavigation__link"
        >
          <dashboard-filters-icon
            :icon="link.icon"
            :label="link.label"
            :is-dropdown="false"
          />
        </router-link>
      </dashboard-filters-v2-item>

      <div class="DashboardFiltersV2-ButtonsContainer">
        <dashboard-filters-v2-item v-if="$slots.default">
          <slot />
        </dashboard-filters-v2-item>
      </div>
    </template>
  </dashboard-filters-v2>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
